import React, { useEffect, useState } from "react";
import "./Landing.css";

const LandingPage = () => {
    const [loading, setLoading] = useState(true);
    const [expandedProject, setExpandedProject] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 200);
        return () => clearTimeout(timer);
    }, []);

    const toggleProject = (index) => {
        setExpandedProject(expandedProject === index ? null : index);
    };

    if (loading) {
        return (
            <div className="loading-screen fade-out">
                <div className="loading-animation"></div>
            </div>
        );
    }

    return (
        <div className="landing-page fade-in">
            <section className="top-section slide-down">
                <div className="header" >
                    <p className="text-fade-in wip-text">UNDER DEVELOPMENT</p>
                    <h1 className="text-fade-in cbl-text">Check back later!</h1>
                </div>
            </section>

            {/* <footer className="footer fade-in">
                <p>&copy;2024 wcked. All rights reserved.</p>
            </footer> */}
        </div>
    );
};

export default LandingPage;
